import React, { Component } from 'react';
import styled from 'styled-components';
import { Box, Title2, Title3, Large, colors } from 'js-components';
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper';
import { Helmet } from 'react-helmet';

import ConversationCloud from '../images/conversationCloudDiagram.svg';

import LayerConnector from '../images/LayerConnector.svg';
import LayerGlobal from '../images/layerGlobal.svg';
import LayerTelephony from '../images/layerTelephony.svg';
import LayerWorkflow from '../images/layerWorkflow.svg';
import ActionEvent from '../images/actionEvent.svg';
import { CallToAction, GetStarted } from '../../components/CallToAction'
import revenueLifecycle from '../images/revenueLifecycle.png';
import { PageTitle, PageTitle2, PageTitle3, PageParagraph, PageRegular, PageLabel, PageSmall } from '../../components/PageTitle';


import Default from '../../components/Layouts/Default';


export default class RevenueProcessAutomation extends Component {

  render() {
    return (
      <Default>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Truly | Sales Process Optimization</title>
          <meta
            name="description"
            content="Like A Full Time Analyst Mining Your Activities & Opportunities for Insights"
          />
        </Helmet>

        <WrapperBackground color={colors.trulyDark} background="telephony1">
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
                What Is Sales Process Optimization?
              </PageTitle2>
              <PageTitle color={colors.white} mb="1.5rem" center>
                The Shortest Path To 2X'ing Revenue Isn't Doubling Headcount Or Leads
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>


        <WrapperSolid>
          <Section flexDirection={['column', 'row']} my="1rem" alignItems="flex-start">
            <Box width={[1, 1]} p="2rem">
              <PageTitle2 as="h2" color={colors.trulyDark} bold mb="1.5rem" mt="1.5rem" center>
                <u>A Perpetual Cycle of Efficiency Gains</u>
              </PageTitle2>
            </Box>

            <Box center width={[1, 1]} p="2rem">
             <WidthImg src={revenueLifecycle} alt="Salesforce Data Analysis" />

              <PageParagraph mb="1.5rem" color={colors.trulyDark} center>
                <br /><br />
                Traditional sales reporting provides high level metrics like leads, activities and win rates, but doesn't help you understand the individual behaviors and events that drive results.  This leaves you with only one optimization lever -- doing more -- and gives you diminishing returns over time.
                <br /><br />
                Sales Process Optimization models your activity data against your sales process continuously to help you understand exactly which behaviors (time to response, active listening, hour of day) lead to the right outcomes.  It helps you understand the highest leverage areas to improve and gives you real-time feedback into whether or not your process/training changes are having impact.
                <br /><br />
                This gives you unparallelled agility
              </PageParagraph>
            </Box>

          </Section>
        </WrapperSolid>



        <Values>
          <Section flexDirection="column">
            <Title2 color={colors.accentFog} mt="32pt">
              5 Pillars Of Sales Process Optimization
            </Title2>
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box width={[1 / 3, 1 / 4]} pr={['0pt', '32pt']} pb={['32pt', '0pt']}>
              <WidthImg src={LayerConnector} alt="Complete Data"/>
            </Box>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                Zero Touch
              </PageTitle3>
              <Large center color={colors.white}>
                You can't rely on humans to 'follow process' to source your CRM data.  There will always be differening behaviors, circumstances and incentives that lead to low-confidence reporting.  <br/><br/>Activity tracking must be complete, invisible and work  without any human intervention.
              </Large>
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                 Accurate, Contextual, Complete
              </PageTitle3>
              <Large center color={colors.white}>
                Managing relationships is an art.  So is modeling and presenting data. <br/><br/>  In order to convert your team into true data-believers, it's not enough to show them generic analyses and expect them to change behavior.  You need a framework to collaboratively iterate on your analyses and experiments.
            </Large>
            </Box>
            <Box width={[1 / 3, 1 / 4]} pl={['0pt', '32pt']} pt={['32pt', '0pt']}>
              <WidthImg src={LayerTelephony} alt="Centralized Data"/>
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box width={[1 / 3, 1 / 4]} pr={['0pt', '32pt']} pb={['32pt', '0pt']}>
              <WidthImg src={LayerGlobal} alt="Fully Customizable"/>
            </Box>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                Fully Customizable
              </PageTitle3>
              <Large center color={colors.white}>
                Every business and customer journey is unique.  Generic AI models and cookie-cutter automations can't possibly capture the many nuances that your team/leaders know about your customers.
                <br/><br/>
                Analytics software must be customizable enough to leverage these insights.  
              </Large>
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
           <Section flexDirection={['column', 'row']}>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                Quality Control Over Short-Term Gains
              </PageTitle3>
              <Large center color={colors.white}>
                The Toyota Production System has been proven as the gold standard in process optimization, allowing companies to dominate in competitive markets for decades (Toyota, Zara, Amazon).
                <br/><br/>
               When you make quality your focus, you unlock a perpetual cycle of efficiency gains that make execution a long-term competitive advantage.
              </Large>
            </Box>
            <Box width={[1 / 3, 1 / 4]} pr={['0pt', '32pt']} pt={['32pt', '0pt']}>
              <WidthImg src={LayerWorkflow} alt="Real Time"/>
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box width={[1 / 3, 1 / 4]} pr={['0pt', '32pt']} pb={['32pt', '0pt']}>
              <WidthImg src={LayerGlobal} alt="Fully Customizable"/>
            </Box>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                Useful to All
              </PageTitle3>
              <Large center color={colors.white}>
                Activity Tracking is only 'big brother' when the information is only available and useful to management.
                <br/><br/>
                When you make the data work for everyone (reps, management, analysts), the quality of every conversation and decision is elevated.  This is where magic happens.
                <br/><br/>
              </Large>
            </Box>
          </Section>          
        </Values>

        <WrapperBackground background="headsetPerson1" gradientType="purple">
          <CallToAction/>
        </WrapperBackground>
        <WrapperBackground background="sf" gradientType="black">
          <GetStarted/>
        </WrapperBackground>
      </Default>
    );
  }
}

const Values = styled.div`
  background-color: #222;
  font-family: 'Josefin Sans';
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
`;

const CenteredImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`;

const WidthImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`;
